import { getDomain } from "../../services/springQuotesApi"

export const searchPublicProductsByName = async (term) => (
	fetch( getDomain()+`/public/products?filter[name]=${term}&sort=name&per_page=50&filter[distinct_references]=1`, { headers: {} })
		.then((response) => response.json())
		.then((res) =>
			res.data.map(product => ({
				label: `${product.name}`,
				value: product.parent_key,
			})),
		)
)