import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Card, CardBody, CardSubtitle, CardTitle } from 'reactstrap'

export default function ProfilesReportTopBar({ title, subtitle }) {
	return (
		<React.Fragment>
			<Card>
				<CardBody>
					<div className='float-right'>
						<Link to="/dashboards/profiles">
							<Button color="primary" outline>Ver otros reportes</Button>
						</Link>
					</div>
					<CardTitle>{title}</CardTitle>
					<CardSubtitle>{subtitle}</CardSubtitle>
				</CardBody>
			</Card>
		</React.Fragment>
	)
}