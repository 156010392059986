import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { faChartArea } from '@fortawesome/free-solid-svg-icons'

import PageTitle from './partials/PageTitle'
import MenuWidget from './partials/MenuWidget'

export default function ResellDashboard() {
	return (
		<React.Fragment>
			<PageTitle title="Recompra Fiel Momentos" subtitle="Reportes relacionados a la gestión de recompra por parte de la fuerza de ventas a miembros de Momentos Spring." />
			<Row>
				<Col sm="12" md="6" lg="4" className="mb-10">
					<MenuWidget 
						title="Reporte por Tiendas"
						subtitle="Resultados ingresados en la plataforma de recompra fiel segmentado por tiendas."
						icon={faChartArea}
						linkTo={"/profiles/top_products"}
					/>
				</Col>
			</Row>
		</React.Fragment>
	)
}