import React, { useState } from 'react'
import { Button, Card, CardBody, Col, Row } from 'reactstrap'
import { Form, InputGroup, Spinner } from 'react-bootstrap'
import { Empty, Table, message } from 'antd'
import moment from 'moment'

import MomentosGeneralTopBar from './partials/MomentosGeneralTopBar'

import { storesResellReport } from './services'
import { handleError, number } from '../../helpers'

export default function StoresResellReport({ reportType }) {
	const [report, setReport] = useState(null)
	const [loading, setLoading] = useState(false)
	const [params, setParams] = useState({ 
		year: moment().startOf('month').format('YYYY').toString(),
		month: moment().endOf('month').format('MM').toString(),
	})

	const onSubmit = () => {
		if(!params.year || !params.month) 
			return message.warning('Debe ingresar unas fechas correctas')

		setLoading(true)

		storesResellReport(params)
			.then(res => setReport(res.data.data))
			.catch(error => handleError(error))
			.finally(() => setLoading(false))
	}

	const columns = [
		{
			title: 'Tienda',
			dataIndex: 'nombre_tienda',
			render: (t, r) => `(${r.codigo_tienda}) ${t}`
		},
		{
			title: 'Registros Ingresados',
			dataIndex: 'total_registros',
			render: t => `${t} registros`
		},
		{
			title: 'Registros Exitosos',
			dataIndex: 'registros_exitosos',
			render: (t) => `${t} exitosos`
		},
		{
			title: 'Registros Fallidos',
			dataIndex: 'registros_fallidos',
			render: t => `${t} fallidos`
		},
		{
			title: 'Registros Pendientes',
			dataIndex: 'registros_fallidos',
			render: (t, r) => `${r.total_registros - t - r.registros_exitosos} pendientes`
		},
		{
			title: 'Asesores',
			dataIndex: 'total_asesores',
			render: t => `${t} asesores`
		},
		{
			title: 'Total en Ventas',
			dataIndex: 'valor_total',
			render: t => `$${number(t)} COP`
		}	
	]

	return (
		<React.Fragment>
			<MomentosGeneralTopBar reportType={reportType} />
			<Card>
				<CardBody>
					<Row>
						<Form.Group className='form-group' as={Col} lg="3" md="4" sm="6">
							<Form.Label>Año de Consulta <span className='text-danger'>*</span></Form.Label>
							<Form.Control
								as="select"
								value={params.year}
								onChange={e => setParams({ ...params, year: e.target.value })}
							>
								<option>2024</option>
								<option>2023</option>
								<option>2022</option>
								<option>2021</option>
								<option>2020</option>
							</Form.Control>
						</Form.Group>
						<Form.Group className='form-group' as={Col} lg="5" md="6">
							<Form.Label>Mes de Consulta <span className='text-danger'>*</span></Form.Label>
							<InputGroup>
								<Form.Control
									as="select"
									value={params.month}
									onChange={e => setParams({ ...params, month: e.target.value })}
								>
									<option value="01">Enero</option>
									<option value="02">Febrero</option>
									<option value="03">Marzo</option>
									<option value="04">Abril</option>
									<option value="05">Mayo</option>
									<option value="06">Junio</option>
									<option value="07">Julio</option>
									<option value="08">Agosto</option>
									<option value="09">Septiembre</option>
									<option value="10">Octubre</option>
									<option value="11">Noviembre</option>
									<option value="12">Diciembre</option>
								</Form.Control>
								<Button color='primary' onClick={onSubmit} disabled={loading}>
									Generar Reporte{" "}
									{ loading && <Spinner size="sm" animation='border' /> }
								</Button>
							</InputGroup>
						</Form.Group>
					</Row>
				</CardBody>
			</Card>
			<Card>
				<CardBody>
					{ report ? (
						<React.Fragment>
							<Table 
								dataSource={report.data}
								rowKey={r => r.tienda_asociada}
								size="small"
								columns={columns}
							/>
						</React.Fragment>
					) : (
						<Empty description="Aún no ha generado el reporte" />
					)}
				</CardBody>
			</Card>
		</React.Fragment>
	)
}