import { Empty, Spin, Table } from 'antd'
import React from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Pie } from 'react-chartjs-2'

import { number, round } from '../../../helpers'

ChartJS.register(ArcElement, Tooltip, Legend)

export default function QuotesReportContent({ report, loading }) {

	const columns = [
		{
			title: 'Genero',
			dataIndex: 'gender',
			render: t => t === 'female' ? 'Mujer' : 'Hombre'
		},
		{
			title: 'Rango de Edad',
			dataIndex: 'age_range',
		},
		{
			title: 'Total Acompañantes',
			dataIndex: 'group_count',
			render: t => `${t} acompañantes`
		},
		{
			title: 'Total Cotizaciones',
			dataIndex: 'total_quotes',
			render: t => `${number(t)} cotizaciones`
		},
		{
			title: '% Peso',
			render: (t, r) => (
				<span>{ round(r.total_quotes / report.data.total * 100).toFixed(1) }%</span>
			)
		},
	]

	const dataGender = {
		labels: report.data.by_gender.map(r => r.gender === 'female' ? 'Mujer' : 'Hombre'),
		datasets: [
		  {
			 label: 'Cotizaciones por Genero',
			 data: report.data.by_gender.map(r => r.total_quotes),
			 backgroundColor: [
				 'rgba(54, 162, 235, 0.2)',
				 'rgba(255, 99, 132, 0.2)',
			  ],
		  },
		],
	}
	
	const dataAgeRange = {
		labels: report.data.by_age_range.map(r => r.age_range),
		datasets: [
		  {
			 label: 'Cotizaciones por Edad',
			 data: report.data.by_age_range.map(r => r.total_quotes),
			 backgroundColor: [
				'rgba(255, 99, 132, 0.2)',
				'rgba(54, 162, 235, 0.2)',
				'rgba(255, 206, 86, 0.2)',
				'rgba(75, 192, 192, 0.2)',
				'rgba(153, 102, 255, 0.2)',
				'rgba(255, 159, 64, 0.2)',
			 ],
		  },
		],
	}

	const dataGroupCount = {
		labels: report.data.by_group_count.map(r => `${parseInt(r.group_count) + 1} personas`),
		datasets: [
		  {
			 label: 'Cotizaciones por número de personas',
			 data: report.data.by_group_count.map(r => r.total_quotes),
			 backgroundColor: [
				'rgba(255, 99, 132, 0.2)',
				'rgba(54, 162, 235, 0.2)',
				'rgba(255, 206, 86, 0.2)',
				'rgba(75, 192, 192, 0.2)',
				'rgba(153, 102, 255, 0.2)',
				'rgba(255, 159, 64, 0.2)',
			 ],
		  },
		],
	 };
	
	 const dataQuoteStatus = {
		labels: report.data.by_status.map(r => `${r.status ? r.status : 'Estado Inicial'}`),
		datasets: [
		  {
			 label: 'Cotizaciones por estado',
			 data: report.data.by_status.map(r => r.total_quotes),
			 backgroundColor: [
				'rgba(255, 99, 132, 0.2)',
				'rgba(54, 162, 235, 0.2)',
				'rgba(255, 206, 86, 0.2)',
				'rgba(75, 192, 192, 0.2)',
				'rgba(153, 102, 255, 0.2)',
				'rgba(255, 159, 64, 0.2)',
			 ],
		  },
		],
	 };

	return (
		<React.Fragment>
			<Row>
				<Col md="4">
					<Spin spinning={loading}>
						<Card>
							<CardBody>
								{ report.data.total > 0 ? (
									<Pie data={dataGender} />
								) : (
									<Empty description="No se encontro cotizaciones con perfiles" />
								)}
							</CardBody>
						</Card>
					</Spin>
				</Col>
				<Col md="4">
					<Spin spinning={loading}>
						<Card>
							<CardBody>
								{ report.data.total > 0 ? (
									<Pie data={dataAgeRange} />
								) : (
									<Empty description="No se encontro cotizaciones con perfiles" />
								)}
							</CardBody>
						</Card>
					</Spin>
				</Col>
				<Col md="4">
					<Spin spinning={loading}>
						<Card>
							<CardBody>
								{ report.data.total > 0 ? (
									<Pie data={dataGroupCount} />
								) : (
									<Empty description="No se encontro cotizaciones con perfiles" />
								)}
							</CardBody>
						</Card>
					</Spin>
				</Col>
			</Row>
			<Spin spinning={loading}>
				<Card>
					<CardBody>
						<Table 
							dataSource={report.data.detail_table}
							columns={columns}
							size="small"
							rowKey={r => r.profile_id}
						/>
					</CardBody>
				</Card>
			</Spin>
			<Row>
				<Col md="5">
					<Spin spinning={loading}>
						<Card>
							<CardBody>
								{ report.data.total > 0 ? (
									<Pie data={dataQuoteStatus} />
								) : (
									<Empty description="No se encontro cotizaciones con perfiles" />
								)}
							</CardBody>
						</Card>
					</Spin>
				</Col>
				<Col>
					<Card>
						<CardBody>
							<Table 
								dataSource={report.data.by_status}
								rowKey={r => r.status}
								size="small"
								columns={[
									{ title: 'Estado', dataIndex: 'status', render: t => t ? t : 'Estado Inicial' },
									{ title: 'Cotizaciones', dataIndex: 'total_quotes', render: t => `${t} cotizaciones` },
								]}
							/>
						</CardBody>
					</Card>				
				</Col>
			</Row>
		</React.Fragment>
	)
}