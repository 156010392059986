import React, { useState } from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'
import { Empty, message } from 'antd'
import moment from 'moment'

import MomentosGeneralTopBar from './partials/MomentosGeneralTopBar'
import MomentosGeneralContent from './partials/MomentosGeneralContent'
import MomentosGeneralForm from './partials/MomentosGeneralForm'

import { momentosGeneralReport } from './services'
import { handleError } from '../../helpers'

export default function MomentosGeneralReport({ reportType }) {
	const [report, setReport] = useState(null)
	const [loading, setLoading] = useState(false)
	const [params, setParams] = useState({ 
		from: moment().startOf('month').format('YYYY-MM-DD').toString(),
		until: moment().endOf('month').format('YYYY-MM-DD').toString(),
		type: reportType
	})

	const onSubmit = () => {
		if(!params.from || !params.until) 
			return message.warning('Debe ingresar unas fechas correctas')

		setLoading(true)

		momentosGeneralReport(params)
			.then(res => setReport(res.data.data))
			.catch(error => handleError(error))
			.finally(() => setLoading(false))
	}

	return (
		<React.Fragment>
			<MomentosGeneralTopBar reportType={reportType} />
			<Row>
				<Col lg="3" md="4">
					<MomentosGeneralForm 
						report={report}
						params={params}
						setParams={setParams}
						onSubmit={onSubmit}
						loading={loading}
					/>
				</Col>
				<Col>
					<Card className='bg-light'>
						<CardBody>
							{ report ? (
								<MomentosGeneralContent 
									report={report}
									loading={loading}
								/>
							) : (
								<Empty description="Aún no ha generado el reporte" />
							)}
						</CardBody>
					</Card>
				</Col>
			</Row>
		</React.Fragment>
	)
}