import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import MomentosGeneralReport from './MomentosGeneralReport'
import StoresResellReport from './StoresResellReport'

export default function Momentos() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/momentos/promo_del_mes" render={() => (
					<MomentosGeneralReport reportType="promo_del_mes" />
				)} />
				
				<Route exact path="/momentos/birthdays" render={() => (
					<MomentosGeneralReport reportType="birthdays" />
				)} />
				
				<Route exact path="/momentos/upselling_protectores" render={() => (
					<MomentosGeneralReport reportType="upselling_protectores" />
				)} />
				
				<Route exact path="/momentos/upselling_almohadas" render={() => (
					<MomentosGeneralReport reportType="upselling_almohadas" />
				)} />

				<Route exact path="/momentos/recompra_almohadas" render={() => (
					<MomentosGeneralReport reportType="recompra_almohadas" />
				)} />
				
				<Route exact path="/momentos/recompra_fiel" render={() => (
					<MomentosGeneralReport reportType="recompra_fiel" />
				)} />

				<Route exact path="/momentos/recompra_fiel_tiendas" render={() => (
					<StoresResellReport reportType="recompra_fiel_tiendas" />
				)} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}