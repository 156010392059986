import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody } from 'reactstrap'
import { Spinner } from 'react-bootstrap'
import { Empty } from 'antd'

import ServerSideSelect from '../../../components/ServerSideSelect'

import { searchPublicProductsByName } from '../../Products/services'
import { number } from '../../../helpers'

export default function SpecificProductForm({ report, params, setParams, onSubmit, loading }) {
	const [selectedProduct, setSelectedProduct] = useState([])

	useEffect(() => {
		if(params.product_parent_key !== selectedProduct.value)
			setParams({ ...params, product_parent_key: selectedProduct.value, product_label: selectedProduct.label })
	}, [ selectedProduct, params, setParams ])

	return (
		<React.Fragment>
			<Card>
				<CardBody>
					<ServerSideSelect 
						value={selectedProduct}
						placeholder="Buscar por código referencia"
						fetchOptions={searchPublicProductsByName}
						onChange={value => setSelectedProduct(value)}
						className="full-width"
						notFoundContent={<Empty description="Sin productos encontrados" imageStyle={{ height: 70 }} />}
					/>
					<p className='text-muted mt-10 mb-0 small'>Producto seleccionado: {params.product_label ? params.product_label : <em>Sin producto seleccionado</em>}</p>
					<Button 
						color='primary' 
						className='mt-15' 
						onClick={onSubmit}
						disabled={loading}
					>
						Generar Reporte{" "}
						{ loading && <Spinner animation='border' size="sm" /> }
					</Button>
				</CardBody>
			</Card>
			{ report && (
				<Card>
					<CardBody>
						<p>Reporte generado para el producto: <b>{report.params.product_label}</b></p>
						<p><b>Total de Registros: </b>{number(report.data.total)} cotizaciones</p>
						<p><b>Consultado el: </b>{report.data.consult_at}</p>
					</CardBody>
				</Card>
			)}
		</React.Fragment>
	)
}