import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import ProfilesAllQuotes from './ProfilesAllQuotes'
import ProfilesWonQuotes from './ProfilesWonQuotes'
import ProfilesLostQuotes from './ProfilesLostQuotes'
import ProfilesSpecificProduct from './ProfilesSpecificProduct'
import ProfileTopProducts from './ProfileTopProducts'

export default function Profiles() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/profiles/all_quotes" component={ProfilesAllQuotes} />
				<Route exact path="/profiles/won_quotes" component={ProfilesWonQuotes} />
				<Route exact path="/profiles/lost_quotes" component={ProfilesLostQuotes} />
				<Route exact path="/profiles/specific_product" component={ProfilesSpecificProduct} />
				<Route exact path="/profiles/top_products" component={ProfileTopProducts} />
				
				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}