import React, { useState } from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'
import { Empty, message } from 'antd'

import ProfilesReportTopBar from './partials/ProfilesReportTopBar'
import QuotesReportContent from './partials/QuotesReportContent'
import SpecificProductForm from './partials/SpecificProductForm'

import { profilesSpecificProductReport } from './services'
import { handleError } from '../../helpers'

export default function ProfilesSpecificProduct() {
	const [report, setReport] = useState(null)
	const [loading, setLoading] = useState(false)
	const [params, setParams] = useState({ 
		product_parent_key: null
	})

	const onSubmit = () => {
		if(!params.product_parent_key) 
			return message.warning('Debe ingresar un producto')

		setLoading(true)
		profilesSpecificProductReport(params)
			.then(res => setReport(res.data.data))
			.catch(error => handleError(error))
			.finally(() => setLoading(false))
	}

	return (
		<React.Fragment>
			<ProfilesReportTopBar 
				title="Perfiles para cotizaciones de un producto"
				subtitle="Para una referencia de producto cual es la edad, genero y número de acompañantes que más lo cotizan"
			/>
			<Row>
				<Col lg="3" md="4">
					<SpecificProductForm 
						report={report}
						params={params}
						setParams={setParams}
						onSubmit={onSubmit}
						loading={loading}
					/>
				</Col>
				<Col>
					<Card>
						<CardBody>
							{ report ? (
								<QuotesReportContent 
									report={report}
									loading={loading}
								/>
							) : (
								<Empty description="Aún no ha generado el reporte" />
							)}
						</CardBody>
					</Card>
				</Col>
			</Row>
		</React.Fragment>
	)
}