import React from 'react'
import { Empty, Table } from 'antd'

export default function TopProductsContent({ report, loading }) {

	const columns = [
		{
			title: 'Posición',
			render: (t, r, i) => `Top ${i + 1}`
		},
		{
			title: 'Producto',
			dataIndex: 'name'
		},
		{
			title: 'Categoría',
			dataIndex: 'category'
		},
		{
			title: 'Referencia',
			dataIndex: 'reference'
		},
		{
			title: 'Total Cotizaciones',
			dataIndex: 'total_quotes',
			render: t => `${t} cotizaciones`
		}
	]

	return (
		<React.Fragment>
			<Table 
				dataSource={report.data.detail_table}
				columns={columns}
				loading={loading}
				rowKey={r => r.reference}
				size="small"
				locale={{ emptyText: <Empty description="Sin cotizaciones asociadas" /> }}
			/>
		</React.Fragment>
	)
}