import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'

export default function MenuWidget({ title, subtitle, icon, linkTo }) {
	return (
		<Link to={linkTo}>
			<Container>
				<div className="clearfix">
					<div className="float-left mr-2 mb-4">
						<FontAwesomeIcon icon={icon} className="icon" />
					</div>
					<div>
						<h6 className="mb-0">{title}</h6>
						<p className="mb-0 hint-text">{subtitle}</p>
					</div>
				</div>
			</Container>
		</Link>
	)
}

const Container = styled.div`
	background-color: #fff;
	padding: 20px;
	border: 3px solid #fff;
	&:hover {
		cursor: pointer;
		background-color: #f8f8f8;
	}
	& .icon {
		font-size: 25px;
		color: #e42537;
	}
	p {
		color: #333;
	}
`