import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import GeneralCampaignReport from './GeneralCampaignReport'

export default function Campaigns() {
    return (
        <React.Fragment>
            <Switch>
                <Route exact path="/campaigns/general" component={GeneralCampaignReport} />

                <Redirect to="/404" />
            </Switch>
        </React.Fragment>
    )
}