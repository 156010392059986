import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Card, CardBody, CardSubtitle, CardTitle } from 'reactstrap'
import { Table } from 'antd'

import { campaignsGeneralReport } from './services'
import { handleError } from '../../helpers'

export default function GeneralCampaignReport() {
    const [campaigns, setCampaigns] = useState(null)

    useEffect(() => {
        !campaigns && campaignsGeneralReport({})
            .then(res => setCampaigns(res.data.data.data))
            .catch(error => handleError(error))
    }, [ campaigns ])

    const columns = [
        {
            title: 'id',
            dataIndex: 'id'
        },
        {
            title: 'Campaña',
            dataIndex: 'name',
        },
        {
            title: 'Segmento',
            dataIndex: 'segment_name',
        },
        {
            title: 'Enviados',
            dataIndex: 'sent_to_number_of_subscribers'
        },
        {
            title: 'Aceptados',
            dataIndex: 'sent_to_number_of_subscribers',
            render: (t, r) => t - r.bounce_count
        },
        {
            title: 'Aperturas',
            dataIndex: 'open_count',
            render: (t, r) => `${t} (${r.unique_open_count} únicos)`
        },
        {
            title: 'Clics',
            dataIndex: 'click_count',
            render: (t, r) => `${t} (${r.unique_click_count} únicos)`
        },
        {
            title: 'Fecha Envió',
            dataIndex: 'sent_at',
        },
    ]

    const expandedRowRender = (r) => (
        <React.Fragment>
            <p><strong>Asunto: </strong>{r.subject}</p>
            <p><strong>Estadísticas actualizadas al: </strong>{r.statistics_calculated_at}</p>
        </React.Fragment>
    )

    return (
        <React.Fragment>
            <Card>
				<CardBody>
					<div className='float-right'>
						<Link to="/dashboards/campaigns">
							<Button color="primary" outline>Ver otros reportes</Button>
						</Link>
					</div>
					<CardTitle>Reporte General de Campañas</CardTitle>
					<CardSubtitle>Campañas de email marketing enviadas masivamente</CardSubtitle>
				</CardBody>
			</Card>
            <Card>
				<CardBody>
                    <Table 
                        dataSource={campaigns}
                        rowKey={r => r.id}
                        columns={columns}
                        loading={!campaigns}
                        expandable={{ expandedRowRender }}
                    />
                </CardBody>
            </Card>
        </React.Fragment>
    )
}