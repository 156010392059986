import React, { useState } from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'
import { Empty } from 'antd'

import ProfilesReportTopBar from './partials/ProfilesReportTopBar'
import TopProductsContent from './partials/TopProductsContent'
import TopProductsForm from './partials/TopProductsForm'

import { profilesTopProductsReport } from './services'
import { handleError } from '../../helpers'

export default function ProfilesTopProducts() {
	const [report, setReport] = useState(null)
	const [loading, setLoading] = useState(false)
	const [params, setParams] = useState({ 
		profile_gender: 'male',
		profile_age_range: '',
		profile_group_count: '0',
	})

	const onSubmit = () => {
		setLoading(true)
		profilesTopProductsReport(params)
			.then(res => setReport(res.data.data))
			.catch(error => handleError(error))
			.finally(() => setLoading(false))
	}

	return (
		<React.Fragment>
			<ProfilesReportTopBar 
				title="Productos más cotizados por perfil"
				subtitle="Ver para un perfil especifico cuales son los productos que más cotiza según las cotizaciones de todas las tiendas"
			/>
			<Row>
				<Col lg="3" md="4">
					<TopProductsForm 
						report={report}
						params={params}
						setParams={setParams}
						onSubmit={onSubmit}
						loading={loading}
					/>
				</Col>
				<Col>
					<Card>
						<CardBody>
							{ report ? (
								<TopProductsContent 
									report={report}
									loading={loading}
								/>
							) : (
								<Empty description="Aún no ha generado el reporte" />
							)}
						</CardBody>
					</Card>
				</Col>
			</Row>
		</React.Fragment>
	)
}