import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Card, CardBody, CardSubtitle, CardTitle } from 'reactstrap'

export default function MomentosGeneralTopBar({ reportType }) {
	const [title, setTitle] = useState('')
	const [subtitle, setSubtitle] = useState('')

	useEffect(() => {
		switch (reportType) {
			case 'promo_del_mes':
				setTitle('Venta por campañas de promo del mes')
				setSubtitle('Venta registradas posteriores a recibir campañas de Momentos Spring')
				break;

			case 'birthdays':
				setTitle('Venta por campañas de cumpleaños')
				setSubtitle('Miembros con venta registradas durante el mes de su cumpleaños')
				break;

			case 'upselling_protectores':
				setTitle('Upselling de protectores')
				setSubtitle('Venta de protectores a clientes de colchones sin compra de protector')
				break;

			case 'upselling_almohadas':
				setTitle('Upselling de almohadas')
				setSubtitle('Venta de almohadas a clientes de colchones sin compra de almohadas')
				break;

			case 'recompra_almohadas':
				setTitle('Recompra de almohadas')
				setSubtitle('Venta de almohadas a clientes previos de almohadas')
				break;
			
			case 'recompra_fiel':
				setTitle('Recompra Fiel Momentos')
				setSubtitle('Reporte general de ventas de plataforma de recompra.spring.com.co')
				break;

			case 'recompra_fiel_tiendas':
				setTitle('Recompra Fiel Momentos por Tienda')
				setSubtitle('Ventas por tiendas registrada en plataforma de recompra.spring.com.co')
				break;
		
			default:
				setTitle('Reporte no configurado')
				break;
		}
	}, [ reportType ])

	return (
		<React.Fragment>
			<Card>
				<CardBody>
					<div className='float-right'>
						<Link to="/dashboards/momentos">
							<Button color="primary" outline>Ver otros reportes</Button>
						</Link>
					</div>
					<CardTitle>{title}</CardTitle>
					<CardSubtitle>{subtitle}</CardSubtitle>
				</CardBody>
			</Card>
		</React.Fragment>
	)
}