import React from "react"
import {useSelector} from "react-redux"
import { Switch, Route, Redirect } from "react-router-dom"

import MainLayout from "./layouts/MainLayout"

import Authentication from './containers/Authentication/'
import Error404 from './containers/ErrorsPages/Error404'
import Dashboards from "./containers/Dashboards"
import Profiles from "./containers/Profiles"
import Momentos from "./containers/Momentos"
import Campaigns from "./containers/Campaigns"

export default function App() {	
	const token = useSelector(state => state.auth.token)
	
	return (
		<React.Fragment>			
			<Switch>
				<Redirect exact path="/" to="/dashboards/profiles" />

				<Route path="/auth" component={Authentication} />
				{!token && <Redirect to="/auth/login" />}

				<MainLayout>
					<Route path="/dashboards" component={Dashboards} />
					<Route path="/profiles" component={Profiles} />
					<Route path="/momentos" component={Momentos} />
					<Route path="/campaigns" component={Campaigns} />
					
					<Route path="/404" component={Error404} />
				</MainLayout>
			</Switch>
		</React.Fragment>
	);	
}