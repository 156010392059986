import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { faChartArea } from '@fortawesome/free-solid-svg-icons'

import PageTitle from './partials/PageTitle'
import MenuWidget from './partials/MenuWidget'

export default function ProfilesDashboard() {
	return (
		<React.Fragment>
			<PageTitle title="Perfiles Cotizador Web" subtitle="Reportes de perfiles que cotizan, compran o visitan en tiendas Spring. Registrado a través del Cotizador Spring." />
			<Row>
				<Col sm="12" md="6" lg="4" className="mb-10">
					<MenuWidget 
						title="Perfiles de todas las cotizaciones"
						subtitle="Para un mes específico ver cual es el perfil de personas con más cotizaciones realizadas"
						icon={faChartArea}
						linkTo={"/profiles/all_quotes"}
					/>
				</Col>
				<Col sm="12" md="6" lg="4" className="mb-10">
					<MenuWidget 
						title="Perfiles de cotizaciones ganadas"
						subtitle="Ver las edades, géneros y número de acompañantes que más tienen cotizaciones con estado 'Compro en Spring'"
						icon={faChartArea}
						linkTo={"/profiles/won_quotes"}
					/>
				</Col>
				<Col sm="12" md="6" lg="4" className="mb-10">
					<MenuWidget 
						title="Perfiles de cotizaciones perdidas"
						subtitle="Ver las edades, géneros y número de acompañantes que más tienen cotizaciones con estado cerrado sin compra"
						icon={faChartArea}
						linkTo={"/profiles/lost_quotes"}
					/>
				</Col>
				<Col sm="12" md="6" lg="4" className="mb-10">
					<MenuWidget 
						title="Perfiles para cotizaciones de un producto"
						subtitle="Para una referencia de producto cual es la edad, genero y número de acompañantes que más lo cotizan"
						icon={faChartArea}
						linkTo={"/profiles/specific_product"}
					/>
				</Col>
				<Col sm="12" md="6" lg="4" className="mb-10">
					<MenuWidget 
						title="Productos más cotizados por perfil"
						subtitle="Ver para un perfil especifico cuales son los productos que más cotiza según las cotizaciones de todas las tiendas"
						icon={faChartArea}
						linkTo={"/profiles/top_products"}
					/>
				</Col>
			</Row>
		</React.Fragment>
	)
}