import React, { useState, useEffect } from 'react'
import { Form, Spinner } from 'react-bootstrap'
import { Button, Card, CardBody } from 'reactstrap'

import { storesRegionsSettings } from '../../Settings/services'
import { handleError } from '../../../helpers'

export default function MomentosGeneralForm({ report, params, setParams, onSubmit, loading }) {
	const [storesRegions, setStoresRegions] = useState(null)

	useEffect(() => {
		!storesRegions && storesRegionsSettings()
			.then(res => setStoresRegions(res.data.data))
			.catch(error => handleError(error))
	}, [ storesRegions ])

	return (
		<React.Fragment>
			<Card>
				<CardBody>
					<p className='bold mb-0'>Facturas con fecha entre:</p>
					<Form.Control 
						type="date"
						value={params.from}
						onChange={e => setParams({...params, from: e.target.value})}
					/>
					<p className='bold mb-0 mt-10'>hasta el dia:</p>
					<Form.Control 
						type="date"
						value={params.until}
						onChange={e => setParams({...params, until: e.target.value})}
					/>
					<p className='bold mb-0 mt-10'>para las regionales:</p>
					<Form.Control
						as="select"
						value={params.region}
						onChange={e => setParams({ ...params, region: e.target.value })}
					>
						<option value="">:: Todas las regionales ::</option>
						{ storesRegions ? (
							<React.Fragment>
								{ storesRegions.map(r => (
									<option key={r.region} value={r.region}>{r.region}</option>
								))}
							</React.Fragment>
						) : <option value="loading" disabled>Cargando...</option> }
					</Form.Control>
					<Button 
						color='primary' 
						className='mt-15' 
						onClick={onSubmit}
						disabled={loading}
					>
						Generar Reporte{" "}
						{ loading && <Spinner animation='border' size="sm" /> }
					</Button>
				</CardBody>
			</Card>
			{ report && (
				<Card>
					<CardBody>
						<p>Visualizando el reporte con facturas desde el <b>{report.params.from}</b> hasta <b>{report.params.until}</b> para <strong>{report.params.region ? report.params.region : 'todas las regionales'}</strong></p>
						<p><b>Consultado el: </b>{report.data.consult_at}</p>
					</CardBody>
				</Card>
			)}
		</React.Fragment>
	)
}