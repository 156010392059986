import React from 'react'
import { Button, Card, CardBody } from 'reactstrap'
import { Form, Spinner } from 'react-bootstrap'

import { number } from '../../../helpers'

export default function TopProductsForm({ report, params, setParams, onSubmit, loading }) {
	return (
		<React.Fragment>
			<Card>
				<CardBody>
					<Form.Group className='form-group'>
						<Form.Label>Genero</Form.Label><br />
						<Form.Check 
							name="gender" 
							value="male" 
							checked={params.profile_gender === 'male'}
							onChange={e => setParams({ ...params, profile_gender: e.target.value })}
							type="radio" 
							inline 
							className="mr-5"
						/>
						<span className="mr-15">Hombre</span>
						<Form.Check 
							name="gender" 
							value="female" 
							checked={params.profile_gender === 'female'}
							onChange={e => setParams({ ...params, profile_gender: e.target.value })}
							type="radio" 
							inline 
							className="mr-5"
						/>
						<span className="mr-15">Mujer</span>
					</Form.Group>
					<Form.Group className='form-group'>
						<Form.Label>Rango de Edad</Form.Label>
						<Form.Control
							as="select"
							value={params.profile_age_range}
							onChange={e => setParams({ ...params, profile_age_range: e.target.value })}
						>
							<option value="">:: Seleccione una opción ::</option>
							<option>Menor de 20 años</option>
							<option>21 a 30 años</option>
							<option>31 a 40 años</option>
							<option>41 a 60 años</option>
							<option>Mayor de 61 años</option>
						</Form.Control>
						<Form.Text className="text-muted">Estime la edad del tomador de decisión</Form.Text>
					</Form.Group>
					<Form.Group className='form-group'>
						<Form.Label>Total de Acompañantes</Form.Label>
						<Form.Control 
							as="select"
							value={params.profile_group_count}
							onChange={e => setParams({ ...params, profile_group_count: e.target.value })}
						>
							<option value="0">Sin acompañantes</option>
							<option value="1">1 acompañante</option>
							<option value="2">2 acompañantes</option>
							<option value="3">3 acompañantes</option>
							<option value="4">4 acompañantes</option>
							<option value="5">5 acompañantes</option>
							<option value="6">6 acompañantes</option>
						</Form.Control>
					</Form.Group>
					<Button 
						color='primary' 
						onClick={onSubmit}
						disabled={loading}
					>
						Generar Reporte{" "}
						{ loading && <Spinner animation='border' size="sm" /> }
					</Button>
				</CardBody>
			</Card>
			{ report && (
				<Card>
					<CardBody>
						<p>Reporte generado para el producto: <b>{report.params.product_label}</b></p>
						<p><b>Total de Registros: </b>{number(report.data.total_quotes)} cotizaciones</p>
						<p><b>Consultado el: </b>{report.data.consult_at}</p>
					</CardBody>
				</Card>
			)}
		</React.Fragment>
	)
}