import React from 'react'
import { Provider } from 'react-redux'
import { HashRouter } from 'react-router-dom'
import ReactDOM from 'react-dom/client'
import { PersistGate } from 'redux-persist/integration/react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

import { persistor, store } from './store'

import App from './App'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'antd/dist/reset.css'
import './assets/css/helpers.css'
import './assets/css/main.css'

const root = ReactDOM.createRoot(document.getElementById('root'))

Bugsnag.start({
  apiKey: '53f64759037909932901f20cf90b8b59',
  plugins: [new BugsnagPluginReact()]
})

const ErrorBoundary = Bugsnag.getPlugin('react')
  .createErrorBoundary(React)

root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <HashRouter>
            <App />
          </HashRouter>
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>
)