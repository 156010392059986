import springReportsApi from '../../services/springReportsApi'

export const profilesAllQuotesReport = data => (
	new Promise((resolve, reject) => {
		springReportsApi()
			.post('/profiles/all_quotes', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const profilesWonQuotesReport = data => (
	new Promise((resolve, reject) => {
		springReportsApi()
			.post('/profiles/won_quotes', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const profilesLostQuotesReport = data => (
	new Promise((resolve, reject) => {
		springReportsApi()
			.post('/profiles/lost_quotes', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const profilesSpecificProductReport = data => (
	new Promise((resolve, reject) => {
		springReportsApi()
			.post('/profiles/specific_product', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const profilesTopProductsReport = data => (
	new Promise((resolve, reject) => {
		springReportsApi()
			.post('/profiles/top_products', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)