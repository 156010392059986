import React from 'react'
import { Redirect, Route, Switch } from 'react-router'

import ProfilesDashboard from './ProfilesDashboard'
import ResellDashboard from './ResellDashboard'
import MomentosDashboard from './MomentosDashboard'
import CampaignsDashboard from './CampaignsDashboard'

export default function Dashboards() {
	return (
		<React.Fragment>
			<Switch>
				<Redirect exact path="/dashboards" to="/dashboards/profiles" />

				<Route exact path="/dashboards/profiles" component={ProfilesDashboard} />
				<Route exact path="/dashboards/resell" component={ResellDashboard} />
				<Route exact path="/dashboards/momentos" component={MomentosDashboard} />
				<Route exact path="/dashboards/campaigns" component={CampaignsDashboard} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}