import springReportsApi from '../../services/springReportsApi'

export const momentosGeneralReport = (data) => (
	new Promise((resolve, reject) => {
		springReportsApi()
			.post(`/momentos/general`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storesResellReport = (data) => (
	new Promise((resolve, reject) => {
		springReportsApi()
			.post(`/momentos/stores_resell`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)