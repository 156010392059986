import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { faChartArea } from '@fortawesome/free-solid-svg-icons'

import PageTitle from './partials/PageTitle'
import MenuWidget from './partials/MenuWidget'

export default function MomentosDashboard() {
	return (
		<React.Fragment>
			<PageTitle title="Ventas Momentos Spring" subtitle="Reportes relacionados a las ventas asociadas al programa de Momentos Spring." />
			<Row>
				<Col sm="12" md="6" lg="4" className="mb-10">
					<MenuWidget 
						title="Ventas por campañas de promo del mes"
						subtitle="Ventas registradas posteriores al envío de las campañas de Momentos Spring"
						icon={faChartArea}
						linkTo={"/momentos/promo_del_mes"}
					/>
				</Col>
				<Col sm="12" md="6" lg="4" className="mb-10">
					<MenuWidget 
						title="Venta por campañas de cumpleaños"
						subtitle="Ventas registradas durante el mes del cumpleaños de miembros de Momentos Spring que recibieron la pieza de cumpleaños"
						icon={faChartArea}
						linkTo={"/momentos/birthdays"}
					/>
				</Col>
				<Col sm="12" md="6" lg="4" className="mb-10">
					<MenuWidget 
						title="Upselling de protectores"
						subtitle="Venta de protectores a clientes de colchones que previamente no habián comprado un protector con Colchones Spring"
						icon={faChartArea}
						linkTo={"/momentos/upselling_protectores"}
					/>
				</Col>
				<Col sm="12" md="6" lg="4" className="mb-10">
					<MenuWidget 
						title="Upselling de almohadas"
						subtitle="Venta de almohadas a clientes de colchones que previamente no habián comprado un almohada con Colchones Spring"
						icon={faChartArea}
						linkTo={"/momentos/upselling_almohadas"}
					/>
				</Col>
				<Col sm="12" md="6" lg="4" className="mb-10">
					<MenuWidget 
						title="Recompra de almohadas"
						subtitle="Recompra de almohadas de clientes que habián comprado previamente almohadas en Colchones Spring"
						icon={faChartArea}
						linkTo={"/momentos/recompra_almohadas"}
					/>
				</Col>
				<Col sm="12" md="6" lg="4" className="mb-10">
					<MenuWidget 
						title="Recompra Fiel Momentos - General"
						subtitle="Ventas registradas por los asesores comerciales en la plataforma de Recompra Fiel Momentos"
						icon={faChartArea}
						linkTo={"/momentos/recompra_fiel"}
					/>
				</Col>
				<Col sm="12" md="6" lg="4" className="mb-10">
					<MenuWidget 
						title="Recompra Fiel Momentos - Tiendas"
						subtitle="Ventas registradas en plataforma Recompra Fiel Momentos agrupado por tiendas"
						icon={faChartArea}
						linkTo={"/momentos/recompra_fiel_tiendas"}
					/>
				</Col>
			</Row>
		</React.Fragment>
	)
}