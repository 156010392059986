import springReportsApi from '../../services/springReportsApi'

export const quoteStatesSettings = data => (
	new Promise((resolve, reject) => {
		springReportsApi()
			.get('/settings/quote_states', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storesRegionsSettings = data => (
	new Promise((resolve, reject) => {
		springReportsApi()
			.get('/settings/stores_regions', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)